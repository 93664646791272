import Header from "@components/Header/Header";
import DashboardRoutes from "@components/Router/DashboardRoutes";
import { Sidebar } from "@components/Sidebar";
import { Spinner } from "@components/Spinner";
import useApplicationRoutes from "@hooks/useApplicationRoutes";
import useRenderDashboard from "@hooks/useRenderDashboard";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { render, setToggleSidebar, sidebarHidden } = useRenderDashboard();
  const { applicationRoutes, sidebarRender } = useApplicationRoutes();
  const { account } = useSelector((state) => state.profile.account);
  let { successfulRegistration } = account;
  const { push } = useHistory();

  useEffect(() => {
    if (render && sidebarRender && !successfulRegistration) {
      push(
        `/${process.env.REACT_APP_DEFAULT_LOCALE}/complete-personal-details`
      );
    }
  }, [render, sidebarRender, successfulRegistration]);

  if (!render || (!sidebarRender && !successfulRegistration))
    return <Spinner />;

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          status={sidebarHidden}
          applicationRoutes={applicationRoutes}
          toggleSidebar={setToggleSidebar}
        />
        <div className="content">
          <Header
            applicationRoutes={applicationRoutes}
            toggleSidebar={setToggleSidebar}
          />
          <main>
            <DashboardRoutes applicationRoutes={applicationRoutes} />
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
