import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { appStrings } from "@components/I18n/index";
import { AppLanguage } from "@const/app-languages";
import { Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { editMyAccount } from "@store/reducers/profile";

export const LanguageSwitcher = () => {
  const { pathname, search } = useLocation();
  const { messages } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { locale } = useIntl();

  const { account } = useSelector((state) => state.profile.account);
  const { successfulRegistration } = account;

  function getMatchingRoute(language) {
    /**
     * Get the key of the route the user is currently on
     */
    const route = pathname.substring(3); // remove local part '/en' from the pathname /en/contact
    const routeKey = Object.keys(messages).find(
      (key) => messages[key] === route
    );

    /**
     * Find the matching route for the new language
     */
    const matchingRoute = appStrings[language][routeKey];

    /**
     * Return localized route
     */
    return matchingRoute ? `/${language}` + matchingRoute : `/${language}`;
  }

  const changeHandler = async (event) => {
    if (account.languageCode && successfulRegistration) {
      await dispatch(
        editMyAccount({
          ...account,
          languageCode: event.target.value.toUpperCase(),
        })
      );
    }
    const npn = pathname.replace(/[^\/][-a-zA-Z]*/, event.target.value);
    history.push(npn + search);
  };

  useEffect(() => {
    if (account.languageCode) {
      const npn = pathname.replace(
        /[^\/][-a-zA-Z]*/,
        account.languageCode.toLowerCase()
      );
      history.push(npn + search);
    } else {
      const npn = pathname.replace(/[^\/][-a-zA-Z]*/, locale);
      history.push(npn + search);
    }
  }, [account.languageCode]);

  return (
    <Input
      type={"select"}
      id={"language-switcher"}
      className={"language-switcher"}
      name={"language-switcher"}
      defaultValue={
        account.languageCode && successfulRegistration
          ? account.languageCode.toLowerCase()
          : AppLanguage[locale]
      }
      onChange={changeHandler}
    >
      {Object.keys(AppLanguage).map((lang) => (
        <option key={lang} value={lang}>
          {AppLanguage[lang]}
        </option>
      ))}
    </Input>
  );
};
