import CompleteAccountRoutes from "@components/Router/CompleteAccountRoutes";
import { applicationRoutes } from "@utils";

const CompleteAccountLayout = () => {
  return (
    <div className="Page">
      <CompleteAccountRoutes applicationRoutes={applicationRoutes} />
    </div>
  );
};

export default CompleteAccountLayout;
