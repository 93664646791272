import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "profile";

export const myAccount = createAsyncThunk(
  `${reducerName}/my-account`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.customer.myAccount,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const myBalance = createAsyncThunk(
  `${reducerName}/my-balance`,
  async (_, thunkAPI) => {
    try {
      const {
        data: { balance },
      } = await apiAction({
        ...apiRoutes.myBalances,
      });

      return balance;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);
export const myCustomerInvoiceData = createAsyncThunk(
  `${reducerName}/invoice-data`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.customerInvoiceData.customerInvoiceData,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const editMyAccount = createAsyncThunk(
  `${reducerName}/edit-my-account`,
  async (data, { rejectWithValue }) => {
    try {
      await apiAction({
        ...apiRoutes.customer.editMyAccount,
        data: data,
      });
      //TODO Kevin Gorjan account mag niet geupdated worden bij wijizigng e-mailadres van "wijzigen logingegevens"
      // ofwel gegevens opnieuw ophalen ofwel store.account niet updaten
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const editMyCustomerInvoiceData = createAsyncThunk(
  `${reducerName}/edit-my-customer-invoice-data`,
  async (data, { rejectWithValue }) => {
    try {
      const { result } = await apiAction({
        ...apiRoutes.customerInvoiceData.editCustomerInvoiceData,
        data: data,
      });

      return result;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const addMyCustomerInvoiceData = createAsyncThunk(
  `${reducerName}/add-my-customer-invoice-data`,
  async (data, { rejectWithValue }) => {
    try {
      const { result } = await apiAction({
        ...apiRoutes.customerInvoiceData.addCustomerInvoiceData,
        data: data,
      });

      return result;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getMyCustomerInvoiceDataSettings = createAsyncThunk(
  `${reducerName}`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.customerInvoiceData.customerInvoiceData,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const editMyCustomerInvoiceDataSettings = createAsyncThunk(
  `${reducerName}/edit-my-customer-invoice-data-settings`,
  async (data, { rejectWithValue }) => {
    try {
      const { result } = await apiAction({
        ...apiRoutes.customerInvoiceData.editCustomerInvoiceDataSettings,
        data: data,
      });
      return result;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const enableDetailedInvoiceItemAction = createAsyncThunk(
  `${reducerName}/enable-detailed-invoice`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.customerInvoiceData.editCustomerInvoiceDataSettings,
        data: {
          showDetailedInvoiceItem: true,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const disableDetailedInvoiceItemAction = createAsyncThunk(
  `${reducerName}/disable-detailed-invoice`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.customerInvoiceData.editCustomerInvoiceDataSettings,
        data: {
          showDetailedInvoiceItem: false,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const newPassword = createAsyncThunk(
  `${reducerName}/new-password`,
  async (data, { rejectWithValue }) => {
    try {
      const { result } = await apiAction({
        ...apiRoutes.customer.newPassword,
        data: data,
      });

      return result;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getLocationsAction = createAsyncThunk(
  `${reducerName}`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.locations.locations,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const myPrefferedLocation = createAsyncThunk(
  `${reducerName}/my-location`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.customer.myLocation,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const editMyPrefferedLocation = createAsyncThunk(
  `${reducerName}/edit-my-location`,
  async (data, { rejectWithValue }) => {
    try {
      await apiAction({
        ...apiRoutes.customer.editMyLocation,
        data: data,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const editMySettings = createAsyncThunk(
  `${reducerName}/edit-my-settings`,
  async (data, { rejectWithValue }) => {
    try {
      await apiAction({
        ...apiRoutes.customer.editSettings,
        data: data,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const customerSlice = createSlice({
  name: reducerName,
  initialState: {
    balance: 0,
    account: {
      account: {},
      error: null,
      isLoading: false,
    },
    invoiceData: {
      invoiceData: {},
      isAdded: false,
      error: null,
      isLoading: false,
    },
    locations: {
      locationList: [],
      prefferedLocation: {},
      data: {},
      isLoading: false,
      error: null,
    },
    password: {
      error: null,
      isLoading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(myBalance.fulfilled, (state, { payload }) => {
      state.balance = payload;
    });
    builder.addCase(myAccount.pending, (state) => {
      state.account.isLoading = true;
      state.account.error = null;
    });
    builder.addCase(myAccount.rejected, (state, { payload }) => {
      state.account.token = null;
      state.account.isLoading = false;
      state.account.error = payload;
    });
    builder.addCase(myAccount.fulfilled, (state, { payload }) => {
      state.account.isLoading = false;
      state.account.account = payload;
    });
    builder.addCase(editMyAccount.pending, (state) => {
      state.account.loading = true;
      state.account.error = null;
    });
    builder.addCase(editMyAccount.rejected, (state, { payload }) => {
      state.account.loading = false;
      state.account.error = payload;
    });
    builder.addCase(editMySettings.pending, (state) => {
      state.account.loading = true;
      state.account.error = null;
    });
    builder.addCase(editMySettings.rejected, (state, { payload }) => {
      state.account.loading = false;
      state.account.error = payload;
    });
    builder.addCase(myCustomerInvoiceData.pending, (state) => {
      state.invoiceData.loading = true;
      state.invoiceData.error = null;
    });
    builder.addCase(myCustomerInvoiceData.fulfilled, (state, { payload }) => {
      state.invoiceData.invoiceData = payload;
    });
    builder.addCase(myCustomerInvoiceData.rejected, (state, { payload }) => {
      state.invoiceData.loading = false;
      state.invoiceData.error = payload;
      state.invoiceData.invoiceData = null;
    });
    builder.addCase(addMyCustomerInvoiceData.pending, (state) => {
      state.invoiceData.loading = true;
      state.invoiceData.error = null;
    });
    builder.addCase(
      addMyCustomerInvoiceData.fulfilled,
      (state, { payload }) => {
        state.invoiceData.loading = false;
        state.invoiceData.isAdded = true;
      }
    );
    builder.addCase(addMyCustomerInvoiceData.rejected, (state, { payload }) => {
      state.invoiceData.loading = false;
      state.invoiceData.error = payload;
      state.invoiceData.isAdded = false;
    });
    builder.addCase(editMyCustomerInvoiceData.pending, (state) => {
      state.invoiceData.loading = true;
      state.invoiceData.error = null;
    });
    builder.addCase(
      editMyCustomerInvoiceData.fulfilled,
      (state, { payload }) => {
        state.invoiceData.loading = false;
      }
    );
    builder.addCase(
      editMyCustomerInvoiceData.rejected,
      (state, { payload }) => {
        state.invoiceData.error = payload;
        state.invoiceData.loading = false;
      }
    );
    builder.addCase(newPassword.pending, (state) => {
      state.password.loading = true;
      state.password.error = null;
    });
    builder.addCase(newPassword.rejected, (state, { payload }) => {
      state.password.loading = false;
      state.password.error = payload;
    });
    builder.addCase(getLocationsAction.pending, (state) => {
      state.locations.isLoading = true;
      state.locations.error = null;
    });
    builder.addCase(getLocationsAction.rejected, (state, { payload }) => {
      state.locations.locationList = [];
      state.locations.isLoading = false;
      state.locations.error = payload;
    });
    builder.addCase(getLocationsAction.fulfilled, (state, { payload }) => {
      state.locations.locationList = payload;
      state.locations.isLoading = false;
    });
    builder.addCase(myPrefferedLocation.pending, (state) => {
      state.locations.loading = true;
      state.locations.error = null;
    });
    builder.addCase(myPrefferedLocation.rejected, (state, { payload }) => {
      state.locations.loading = false;
      state.locations.error = payload;
    });
    builder.addCase(myPrefferedLocation.fulfilled, (state, { payload }) => {
      state.locations.loading = false;
      state.locations.prefferedLocation = payload;
    });
    builder.addCase(editMyPrefferedLocation.pending, (state) => {
      state.locations.loading = true;
      state.locations.error = null;
    });
    builder.addCase(editMyPrefferedLocation.rejected, (state, { payload }) => {
      state.locations.loading = false;
      state.locations.error = payload;
    });
  },
});

export const actions = customerSlice.actions;

export default customerSlice.reducer;
